import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';

export default function Skills() {
    return (
        <div class="py-5 text-center bg-dark text-white" >
        <ScrollAnimation animateIn='animate__animated animate__fadeInUp' duration={0.3}>
        <div class="col align-self-center skills-div">
            <h1 className="skills-title display-5 fw-bold" id="skills">Some Things I Know</h1> 
            <ScrollAnimation animateIn='animate__animated animate__fadeInTopLeft' duration={1}>
            <div className="skills-info">
            <button class="buttons" disabled>Javascript</button>
            <button class="buttons" disabled>Java</button>
            <button class="buttons" disabled>ReactJS</button>
            <button class="buttons" disabled>HTML</button>
            <button class="buttons" disabled>Adobe Lightroom</button>
            </div>
            </ScrollAnimation>
        </div>
        <ScrollAnimation animateIn='animate__animated animate__fadeInBottomRight' duration={1.2}>
        <div class="col align-self-center about-div">
            <button class="buttons" disabled>Elm</button>
            <button class="buttons" disabled>Haskell</button>
            <button class="buttons" disabled>C++</button>
            <button class="buttons" disabled>Python</button>
            <button class="buttons" disabled>CSS</button>
            <button class="buttons" disabled>Git</button>
        </div>
        </ScrollAnimation>
        </ScrollAnimation>
        </div>
    )
}

