import React from "react";
import Typed from "react-typed";

export default function Header  ()  {
    return (
    <div className="header-wraper" >
        <div className="main-info">
            <h1 >Hi, I'm Zaid</h1>
            <Typed 
                className="typed-text"
                strings={["I'm a Developer", "I'm a Photographer", "I'm a Baker"]}
                typeSpeed={50}
                backSpeed={50}
                loop
            />
        </div>
    </div>
    )
}


