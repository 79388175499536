import { PROPERTY_TYPES } from '@babel/types';
import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';


export default function About () {
    return (
        <div class="py-5 text-center bg-dark text-white" >
        <ScrollAnimation animateIn='animate__animated animate__fadeInUp' duration={0.3}>
        <div class="col align-self-center about-div">
             <h1 className="about-title display-5 fw-bold" id="about">About Me</h1>
             <p className="about-info">I’m a 3rd Year Computer Science undergraduate living in London. I enjoy problem solving and exploring the creative nature of front-end web development. I occasionally take my camera with me on my walks capturing the landscape around me. I run a small baking business for close friends and family specialising in party sized cupcakes.</p>
        </div>
        </ScrollAnimation>
        </div>
    )
}


