import React from "react"
import logo from "../Z.A.png";

export default function Navbar () {
    return (
<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
<div className="container-fluid" id="navs">
  <a className="navbar-brand" ><img className="logo" src={logo} alt="logo..." /></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
   style={{color: "#F2E0BD"}}>
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ms-auto">
      <li className="nav-item">
        <a className="nav-link" href="#about">About</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#skills">Skills</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#projects">Projects</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="#hobbies">Hobbies</a>
      </li>
    </ul>
  </div>
  </div>
</nav>
    )
}