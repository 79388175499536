import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import {Particles} from 'react-particles-js';
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import About from "./components/About"
import Skills from './components/Skills';
import Projects from './components/Projects';
import Hobbies from './components/Hobbies';

function App() {
  return (
    <>
    <Particles
      params={{
        particles: {
          number: {
            value: 30,
            density: {
              enable: true,
              value_area: 5000
            }
          },
          shape: {
            type: "circle",
            stroke: {
              width : 3,
              color: "#D92B4B"
            }
          }
        }
      }
      }
    />
    <Navbar />
    <Header />
    <About />
    <Skills />
    <Projects/>
    <Hobbies/>
    </>
  );
}

export default App;
