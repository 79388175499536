import React from 'react'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import gamepicture from "../gamepicture.jpeg";
import game from "../game.mov"
import HoverVideoPlayer from "react-hover-video-player";
import robotgame from "../robotgame.png"
import discordLogo from "../discordLogo.png"

export default function Projects() {
    return (
        <div class="py-5 text-center bg-dark text-white">
            <ScrollAnimation animateIn='animate__animated animate__fadeInUp' duration={0.3}>
                <div class="col align-self-center project-div">
                    <h1 className="projects-title display-5 fw-bold" id="projects">Most Notable Projects</h1> 
                    <div className="row flex-lg-row-reverse align-items-center py-5">
                        <div className="col-10 col-sm-8 col-lg-6">
                <ScrollAnimation animateIn='animate__animated animate__fadeInRight' duration={1}>
                    <HoverVideoPlayer
                        videoSrc={game}
                        style={{
                            width: '75%',
                        }}
                        pausedOverlay={
                            <img
                            src={gamepicture}
                            alt=""
                            style={{
                                width: "100%",
                                height: '100%',
                                objectFit: 'cover',
                             }}
                         />
                         }
                />
                </ScrollAnimation>
                        </div>
                        <div className="col-lg-6">
                <ScrollAnimation animateIn='animate__animated animate__fadeInLeft' duration={1}>
                    <h2>Java 2D Platform Side Scroller</h2>
                    <p className="project-info">This is a 2D platform side scroller game I created using java. It was a fun project I undertook at university and helped me understand Object Oriented 
                    Programming really well. The game has 3 levels, is able to record your highscore as well as load previous save files.</p>
                </ScrollAnimation>
                        </div>
                    </div>
                <div className="row flex-lg-row-reverse align-items-center py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                    <ScrollAnimation animateIn='animate__animated animate__fadeInRight' duration={1}>
                        <h2>C++ Battleship Robot Game</h2>
                        <p className="project-info">I created a simple battleship like game in C++, that would read movement inputs from a text file and output the results in the terminal.
                        This project allowed me to deepen my understanding of C++, from project structure to using header files to minimise errors.</p>
                        
                    </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                    <ScrollAnimation animateIn='animate__animated animate__fadeInLeft' duration={1}>
                        <img src={robotgame} classname="w-50" alt="robot"/>
                    </ScrollAnimation>
                    </div>
                </div>
                <div className="row flex-lg-row-reverse align-items-center py-5">
                    <div className="col-10 col-sm-8 col-lg-6">
                    <ScrollAnimation animateIn='animate__animated animate__fadeInRight' duration={1}>
                        <img src={discordLogo} classname="w-50" alt="discord logo"/>
                    </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                    <ScrollAnimation animateIn='animate__animated animate__fadeInLeft' duration={1}>
                        <h2>Javascript Discord Bot</h2>
                        <p className="project-info">I created a discord bot using DiscordJs to interact with the DiscordAPI. The bot allows users to check information about other users as well as server
                        information.  The end goal is to allow users to play turn based games with the bot. Currently the only game you can play is tic tac toe.</p>
                    </ScrollAnimation>
                    </div>
                </div>
                </div>
            </ScrollAnimation>
        </div>
    )
}
